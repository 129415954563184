<template>
  <div>
      <b-loading :is-full-page="true" :active="(users||[]).length===0"></b-loading>
      <div class="card is-shadowless" style="border: 2px dashed rgba(0,0,0,0.13);">
        <div class="card-content">
          <b-field grouped group-multiline>
            <div class="control">
              <b-switch v-model="filterNotVerified">{{ $t('users.notVerified') }}</b-switch>
            </div>
            <div class="control">
              <b-input :placeholder="$t('search')" style="top: -8px;" v-model="textFilter"/>
            </div>
            <div class="control" v-if="$api.canEdit()">
              <button class="button is-primary" style="top: -8px;" @click="$router.push('/users/-1')">
                {{ $t('users.addNewUser') }}
              </button>
            </div>
          </b-field>

          <b-table :data="filteredUsers">

            <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
              {{ props.row.id }}
            </b-table-column>

            <b-table-column field="first_name" :label="$tc('users.firstName')" v-slot="props">
              {{ props.row.first_name }}
            </b-table-column>

            <b-table-column field="last_name" :label="$tc('users.lastName')" v-slot="props">
              {{ props.row.last_name }}
            </b-table-column>

            <b-table-column field="email" :label="$tc('users.email')" v-slot="props">
              {{ props.row.email }} <span v-if="props.row.is_inspector" class="tag is-success">Inspektor</span>
            </b-table-column>

            <b-table-column field="language" :label="$tc('users.language')" v-slot="props">
              {{ props.row.language == 'polish' ? $t('languages.polish') : $t('languages.english') }}
            </b-table-column>

            <b-table-column field="organisation" :label="$tc('users.organisation')" v-slot="props">
              {{ props.row.organisation || $t('users.noData') }}
            </b-table-column>

            <b-table-column field="is_verified" :label="$tc('users.isVerified')" v-slot="props">
              {{ props.row.is_verified ? $t('users.yes') : $t('users.no') }}
            </b-table-column>

            <b-table-column field="is_admin" :label="$tc('users.isAdmin')" v-slot="props">
              {{ props.row.is_admin ? $t('users.yes') : $t('users.no') }}
            </b-table-column>

            <b-table-column field="ppl_exams_passed" label="Postęp PPLA" v-slot="props">
              {{
                (props.row.ppl_exam_status === 'passed') ? 'Zakończone pozytywnie' : ((props.row.ppl_exam_status === 'failed' ? 'Zakończone negatywnie' : 'W toku'))
              }}
            </b-table-column>

            <b-table-column :label="$tc('users.actions')" v-slot="props">
              <button class="button is-warning is-small mr-2" @click="edit(props.row)">{{ $t('users.edit') }}</button>
              <button class="button is-danger is-small" @click="deleteUser(props.row)"
                      v-if="props.row.id!==$api.user.id && $api.canEdit()">{{ $t('users.destroy') }}
              </button>
            </b-table-column>

            <template #empty>
              <div class="has-text-centered">{{ $t('users.noData') }}</div>
            </template>

          </b-table>

        </div>
      </div>
  </div>
</template>

<script>
import HomeWrapper from "../components/HomeWrapper";

export default {
  name: "Users",
  components: {HomeWrapper},
  data() {
    return {
      users: [],
      filterNotVerified: false,
      textFilter: '',
      showEditingModal: false,
      selectedUser: null,
      courses: []
    };
  },
  async mounted() {
    await this.load();
  },
  computed: {
    filteredUsers() {
      if (this.textFilter.toString().length > 0) return this.users.filter(x => Object.values(x).join('').toLowerCase().includes(this.textFilter.toLowerCase()));
      if (this.filterNotVerified) return this.users.filter(x => x.is_verified === 0);
      return this.users;
    }
  },
  methods: {
    async load() {
      this.users = (await this.$api.getUsers());
      this.courses = (await this.$api.getCourses()).data;
    },
    edit(user) {
      this.$router.push(`/users/${user.id}`);
    },
    deleteUser(user) {
      this.$buefy.snackbar.open({
        message: `Jesteś pewien usunięcia użytkownika ${user.first_name} ${user.last_name} (${user.email})?`,
        type: 'is-danger',
        position: 'is-top',
        actionText: 'Usuń teraz!',
        indefinite: true,
        onAction: async () => {
          await this.$api.deleteUser(user);
          this.showEditingModal = false;
          await this.load();
        }
      })
    },
  }
}
</script>

<style scoped>
</style>
