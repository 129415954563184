<script>
import HomeWrapper from "@/components/HomeWrapper.vue";
import dayjs from "dayjs";

export default {
  name: "Scores",
  components: { HomeWrapper },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.scores = (await this.$api.getScores());
    },
    humanTime(input) {
      const m = Math.floor(input / 60);
      const s = Math.floor(input % 3600 % 60);
      return `${m} min. ${s} s.`;
    },
    deleteScore(score) {
      this.$buefy.snackbar.open({
        message: `Jesteś pewien usunięcia podejścia użytkownika ${score.user.first_name} ${score.user.last_name} do ${score.label}?`,
        type: 'is-danger',
        position: 'is-top',
        actionText: 'Usuń teraz!',
        indefinite: true,
        onAction: async () => {
          await this.$api.deleteScore(score);
          await this.load();
        }
      })
    },
    async editScoreScore(score) {
      const newScore = parseInt(prompt('Edycja wyniku procentowego'));
      if (isNaN(newScore)) return;
      console.log(newScore);
      if (newScore < 0 || newScore > 100) return;
      score.score = newScore;
      await this.$api.editScore(score);
      await this.load();
    },
    async editScoreDate(score) {
      let newDate = prompt('Edycja daty podejścia (podaj w formacie: \'DD/MM/YYYY hh:mm\')', dayjs(score.created_at).format('MM/DD/YYYY HH:mm'));
      newDate = dayjs(newDate, 'MM/DD/YYYY HH:mm');
      if (!newDate.isValid()) return;
      score.created_at = newDate;
      await this.$api.editScore(score);
      await this.load();
    }
  },
  computed: {
    dayjs() {
      return dayjs
    },
    filteredScores() {
      const correctScores = this.scores.filter(x => x.user != null);
      if (this.textFilter.toString().length > 0) return correctScores.filter(x => JSON.stringify(x).toLowerCase().includes(this.textFilter.toLowerCase()));
      return correctScores;
    }
  },
  data() {
    return {
      scores: [],
      textFilter: ''
    };
  },
}
</script>

<template>
  <div>
    <div class="card is-shadowless" style="border: 2px dashed rgba(0,0,0,0.13);">
      <div class="card-content">
        <b-field grouped group-multiline>
          <div class="control">
            <b-input :placeholder="$t('search')" style="top: -8px;" v-model="textFilter" />
          </div>
        </b-field>

        <b-table :data="filteredScores">

          <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
            {{ props.row.id }}
          </b-table-column>

          <b-table-column field="user_id" label="Użytkownik" v-slot="props">
            <a @click="$router.push('/users/' + props.row.user_id)">{{ props.row.user.first_name }} {{
              props.row.user.last_name }}</a>
          </b-table-column>

          <b-table-column field="label" label="Znacznik" v-slot="props">
            {{ props.row.label }}
          </b-table-column>

          <b-table-column field="score" label="Wynik procentowy" v-slot="props">
            <a @click="editScoreScore(props.row)">{{ props.row.score }}%</a>
          </b-table-column>

          <b-table-column field="created_at" label="Data podejścia" v-slot="props">
            <a @click="editScoreDate(props.row)">{{ dayjs(props.row.created_at).format('MM/DD/YYYY HH:mm') }}</a>
          </b-table-column>

          <b-table-column field="time" label="Czas" v-slot="props">
            {{ humanTime(props.row.time) }}
          </b-table-column>

          <b-table-column :label="$tc('users.actions')" v-slot="props">
            <button class="button is-danger is-small" @click="deleteScore(props.row)" v-if="$api.canEdit()">{{
              $t('users.destroy')
            }}
            </button>
          </b-table-column>

          <template #empty>
            <div class="has-text-centered">{{ $t('users.noData') }}</div>
          </template>

        </b-table>
      </div>
    </div>
  </div>
</template>

<style scoped></style>