<script>
import HomeWrapper from "@/components/HomeWrapper.vue";
import dayjs from "dayjs";

export default {
  name: "User",
  components: { HomeWrapper },
  mounted() {
    this.load();
  },
  methods: {
    deleteScore(score) {
      this.$buefy.snackbar.open({
        message: `Jesteś pewien usunięcia podejścia użytkownika ${score.user.first_name} ${score.user.last_name} do ${score.label}?`,
        type: 'is-danger',
        position: 'is-top',
        actionText: 'Usuń teraz!',
        indefinite: true,
        onAction: async () => {
          await this.$api.deleteScore(score);
          await this.load();
        }
      })
    },
    async editScoreScore(score) {
      const newScore = parseInt(prompt('Edycja wyniku procentowego'));
      if (isNaN(newScore)) return;
      console.log(newScore);
      if (newScore < 0 || newScore > 100) return;
      score.score = newScore;
      await this.$api.editScore(score);
      await this.load();
    },
    async editScoreDate(score) {
      let newDate = prompt('Edycja daty podejścia (podaj w formacie: \'DD/MM/YYYY hh:mm\')', dayjs(score.created_at).format('MM/DD/YYYY HH:mm'));
      newDate = dayjs(newDate, 'MM/DD/YYYY HH:mm');
      if (!newDate.isValid()) return;
      score.created_at = newDate;
      await this.$api.editScore(score);
      await this.load();
    },
    humanTime(input) {
      const m = Math.floor(input / 60);
      const s = Math.floor(input % 3600 % 60);
      return `${m} min. ${s} s.`;
    },
    create() {
      this.user = {
        id: -1,
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        is_verified: 1,
        assignedCoursesIds: []
      };
    },
    async load() {
      if (parseInt(this.$route.params.user_id) === -1) {
        this.create();
        return;
      }

      const user = (await this.$api.getUser(this.$route.params.user_id));
      this.courses = (await this.$api.getCourses()).data;
      this.scores = (await this.$api.getScores());
      user.assignedCoursesIds = JSON.parse(user.assigned_courses != null ? (user.assigned_courses.length > 0 ? user.assigned_courses : '[]') : '[]').filter(x => x[1] === 'on').map(x => x[0]);
      user.assignedCoursesDecoded = {};
      for (const decoded of JSON.parse(user.assigned_courses.length > 0 ? user.assigned_courses : '[]')) {
        user.assignedCoursesDecoded[decoded[0]] = decoded;
      }
      this.user = user;
    },
    async assignCourse(course, val) {
      if (!this.user.assigned_courses) this.user.assigned_courses = '[]';
      if (val) {
        const assigned = JSON.parse(this.user.assigned_courses);
        const index = assigned.findIndex(x => x[0] === course.id);
        if (index < 0)
          assigned.push([course.id, 'on', 0]);
        else
          assigned[index][1] = 'on';
        this.user.assigned_courses = JSON.stringify(assigned);
      } else {
        const assigned = JSON.parse(this.user.assigned_courses);
        const index = assigned.findIndex(x => x[0] === course.id);
        assigned[index][1] = 'off';
        this.user.assigned_courses = JSON.stringify(assigned);
      }
      this.user.assignedCoursesIds = JSON.parse(this.user.assigned_courses != null ? (this.user.assigned_courses.length > 0 ? this.user.assigned_courses : '[]') : '[]').filter(x => x[1] === 'on').map(x => x[0]);
    },
    sumTime(trackingData) {
      let total = 0;
      for (const k in trackingData) {
        if (k !== 'x') total += trackingData[k];
      }
      const h = Math.floor(total / 3600);
      const m = Math.floor(total % 3600 / 60);
      const s = Math.floor(total % 3600 % 60);
      return `${h}h. ${m} min. ${s} s.`;
    },
    downloadProtocol(user, course, pdf = false) {
      window.open(`${this.$api.config.endpoint}questions/scores/${user.id}/${course.id}${pdf ? '?pdf=yes' : ''}`, '_blank').focus();
    },
    resetScores(user, course) {
      this.$buefy.snackbar.open({
        message: `Jesteś pewien zresetowania podejść tego użytkownika?`,
        type: 'is-danger',
        position: 'is-top',
        actionText: 'Zresetuj!',
        indefinite: true,
        onAction: async () => {
          await this.$api.resetScores(user.id, course.id);
          await this.load();
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Pomyślnie zresetowano!',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      })
    },
    async save() {
      if (this.user.id === -1) {
        try {
          const user = await this.$api.register(this.user.first_name, this.user.last_name, this.user.email, this.user.password, false);
          this.user.id = user.id;
          await this.$api.editUser(this.user);
          await this.$router.push(`/users/${user.id}`);
        } catch (err) {
          this.user.id = -1;
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Email zajęty',
            position: 'is-top',
            type: 'is-danger'
          })
        }
      } else {
        await this.$api.editUser(this.user);
        await this.load();
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Zapisano',
          position: 'is-top',
          type: 'is-success'
        })
      }
    },
  },
  computed: {
    dayjs() {
      return dayjs
    },
    filteredScores() {
      const self = this;
      return this.scores.filter(x => parseInt(x.user_id) === self.user.id);
    }
  },
  data() {
    return {
      user: null,
      courses: [],
      scores: []
    }
  }
}
</script>

<template>
  <div>
    <b-loading :is-full-page="true" :active="user == null"></b-loading>
    <div class="card is-shadowless" style="border: 2px dashed rgba(0,0,0,0.13);">
      <div class="card-content">

        <div v-if="user != null">
          <div>
            <div class="columns">
              <div class="column">
                <b-input :placeholder="$t('users.firstName')" class="control" v-model="user.first_name" />
                <b-input :placeholder="$t('users.lastName')" class="control mt-4" v-model="user.last_name" />
                <b-input :placeholder="$t('users.email')" class="control mt-4" v-model="user.email" />
              </div>
            </div>
            <b-input :placeholder="$t('users.password')" type="password" password-reveal class="control"
              v-model="user.password" />
            <div class="columns mt-2">
              <div class="column">
                <b-select :placeholder="$tc('users.language')" class="control" :expanded="true" v-model="user.language">
                  <option value="polish">{{ $t('languages.polish') }}</option>
                  <option value="english">{{ $t('languages.english') }}</option>
                </b-select>
              </div>
              <div class="column">
                <b-input :placeholder="$t('users.organisations')" class="control" v-model="user.organisation" />
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-input placeholder="PESEL" class="control" v-model="user.pesel" />
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-switch v-model="user.is_verified" true-value="1" false-value="0">
                  {{ $t('users.isVerified') }}
                </b-switch>
                <br />
                <b-switch class="mt-2" v-model="user.is_writer" true-value="1" false-value="0">
                  {{ $t('users.isEditor') }}
                </b-switch>
                <br />
                <b-switch class="mt-2" v-model="user.is_admin" true-value="1" false-value="0">
                  {{ $t('users.isAdmin') }}
                </b-switch>
                <br />
                <b-switch class="mt-2" v-model="user.is_inspector" true-value="1" false-value="0"
                  v-if="!$api.user.is_inspector">
                  Czy inspektor?
                </b-switch>
                <b-field :label="$tc('users.courses')" style="margin-top: 16px;"></b-field>
                <div v-for="course in courses">
                  <b-switch class="mb-2" @input="(v) => assignCourse(course, v)"
                    :value="user.assignedCoursesIds.includes(course.id)">
                    {{ course.name }}
                  </b-switch>
                  <!--                      <button class="button is-small is-warning" @click="downloadProtocol(user,course)"-->
                  <!--                              v-if="course.report_type!=null&&course.report_type.length>0&&course.report_type!=='off'">-->
                  <!--                        {{ $t('users.report') }}-->
                  <!--                      </button>-->
                  <b-dropdown aria-role="list" :triggers="['hover']"
                    v-if="course.report_type != null && course.report_type.length > 0 && course.report_type !== 'off'">
                    <template #trigger="{ active }">
                      <b-button :label="$t('users.report')" type="is-warning is-small"
                        :icon-right="active ? 'menu-up' : 'menu-down'" />
                    </template>

                    <b-dropdown-item aria-role="listitem" @click="downloadProtocol(user, course, true)">PDF
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" @click="downloadProtocol(user, course)"
                      v-if="$api.canEdit()">Excel
                    </b-dropdown-item>
                  </b-dropdown>
                  <span style="margin-left: 8px;"
                    v-if="user.assignedCoursesDecoded != null && user.assignedCoursesDecoded.hasOwnProperty(course.id)">
                    {{ sumTime(user.assignedCoursesDecoded[course.id][2]) }}
                  </span>
                  <button class="button is-small is-danger" @click="resetScores(user, course)" v-if="$api.canEdit()">
                    {{ $t('users.resetScores') }}
                  </button>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column" align="right" v-if="$api.canEdit()">
                <button class="button is-success" @click="save"
                  :disabled="user != null && (user.email.length === 0 || user.first_name.length === 0 || user.last_name.length === 0)">
                  {{ $t('users.save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div style="border: 2px dashed rgba(0,0,0,0.13); margin-top: 16px;" v-if="!$api.user.is_inspector">
      <b-table :data="filteredScores">

        <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="label" label="Znacznik" v-slot="props">
          {{ props.row.label }}
        </b-table-column>

        <b-table-column field="score" label="Wynik procentowy" v-slot="props">
          <a @click="editScoreScore(props.row)">{{ props.row.score }}%</a>
        </b-table-column>

        <b-table-column field="created_at" label="Data podejścia" v-slot="props">
          <a @click="editScoreDate(props.row)">{{ dayjs(props.row.created_at).format('MM/DD/YYYY HH:mm') }}</a>
        </b-table-column>

        <b-table-column field="time" label="Czas" v-slot="props">
          {{ humanTime(props.row.time) }}
        </b-table-column>


        <b-table-column :label="$tc('users.actions')" v-slot="props">
          <button class="button is-danger is-small" @click="deleteScore(props.row)" v-if="$api.canEdit()">{{
            $t('users.destroy') }}
          </button>
        </b-table-column>

        <template #empty>
          <div class="has-text-centered">{{ $t('users.noData') }}</div>
        </template>

      </b-table>
    </div>
  </div>
</template>

<style scoped></style>